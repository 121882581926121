export function ReturnSourceFillColor(source) {
	switch (source) {
		case 'yelp':
			return '#c11a02';
		case 'google':
			return '#fabc04';
		case 'bbb':
			return '#005e88';
		case 'guildquality':
			return '#034473';
		case 'homeadvisor':
			return '#fa931c';
		case 'facebook':
			return '#3d5a99';
		case 'houzz':
			return '#8bc542';
		case 'angieslist':
			return '#339c3c';
		default:
			return null;
	}
}
